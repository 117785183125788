import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import useNotifications from "@notifications"

export default function useContractsList() {

    // Use toast
	const { toastNotification } = useNotifications()

    const refContractListTable = ref(null);

	const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
        { key: 'provider_name', sortable: true, label: 'PROVEEDOR', thClass: styleColumns.thClass },
		{ key: 'contract_type_name', sortable: true, label: 'TIPO <br/> DOCUMENTO', ...styleColumns },
        { key: 'contract_number', sortable: true, label: 'N° CONTRATO', ...styleColumns  },
        { key: 'name', sortable: true, label: 'TÍTULO DEL <br/> CONTRATO', ...styleColumns  },
        { key: 'contract_service_name', sortable: true, label: 'TIPO <br/> SERVICIO', ...styleColumns  },
        { key: 'start_date', sortable: true, label: 'FECHA <br/> INICIO ', ...styleColumns  },
        { key: 'end_date', sortable: true, label: 'FECHA <br/> CULMINACIÓN', ...styleColumns  },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns  }
    ];

    const perPage = ref(10);
    const totalContracts = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const contractTypesFilter = ref(null);
	const contractServicesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refContractListTable.value ? refContractListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalContracts.value,
        }
    });

    const refetchData = () => {
        refContractListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, contractTypesFilter, contractServicesFilter], () => {
        refetchData();
    });

    const fetchContracts = (ctx, callback) => {
        store
            .dispatch('app-contract/fetchContracts', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                contractTypes: contractTypesFilter.value,
				contractServices: contractServicesFilter.value,
            })
            .then(response => {
                const { contracts, total } = response.data;
                callback(contracts);
                totalContracts.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener lista de contratos', false)
            })
    };

    return {
        fetchContracts,
        tableColumns,
        perPage,
        currentPage,
        totalContracts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refContractListTable,

        refetchData,

        // Extra Filters
        contractTypesFilter,
        contractServicesFilter
    }
}