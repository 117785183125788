<template></template><template>
	<div>
		<!-- Filters -->
		<contracts-list-filters
			:options="options"
			:contract-types-filter.sync="contractTypesFilter"
			:contract-services-filter.sync="contractServicesFilter"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar proveedor, Nro o título de contrato"
								trim
							/>
							<b-button
								:to="{ name: 'contracts-create' }"
								variant="primary"
							>
								<span class="text-nowrap">Nuevo Contrato</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refContractListTable"
				class="position-relative table-contracts"
				:style="'height: inherit'"
				:items="fetchContracts"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(contract_type_name)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(contract_service_name)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(name)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(start_date)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(end_date)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(provider_name)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="null"
								:text="avatarText(data.item.provider_name)"
								variant="light-success"
								:to="{ name: 'providers-edit', params: { id: data.item.id } }"
							/>
						</template>
						<b-link
							:to="{ name: 'providers-edit', params: { id: data.item.id } }"
							class="font-weight-bold d-block text-nowrap"
						>{{ data.item.provider_name }}</b-link>
					</b-media>
				</template>

				<template #cell(start_date)="data">
					<b-badge
						variant="light-primary"
						class="text-nowrap"
					>{{ formatStringToDate(data.item.start_date, 'DD-MM-YYYY') }}</b-badge>
				</template>

				<template #cell(end_date)="data">
					<b-badge
						variant="light-primary"
						class="text-nowrap"
					>{{ formatStringToDate(data.item.end_date, 'DD-MM-YYYY') }}</b-badge>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'contracts-edit', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteContract(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalContracts"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import useNotifications from "@notifications"
import { formatStringToDate } from "@core/utils/filter"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import ContractsListFilters from "./ContractsListFilters.vue"
import contractStoreModule from "../contractStoreModule"
import useContractsList from "./useContractsList"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		ContractsListFilters,
		vSelect
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()

		// REFS
		const options = ref({})

		const CONTRACT_APP_STORE_MODULE_NAME = "app-contract"

		// REGISTER MODULE
		if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
			store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME))
				store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [services, types] = await Promise.all([
					axios.get("/selectors/contract-services"),
					axios.get("/selectors/contract-types")
				])
				options.value = {
					...services.data,
					...types.data
				}
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const deleteContract = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar el contrato?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-contract/deleteContract", { id })
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message || "Error al eliminar contrato"
							swalNotification(message, false)
						})
				}
			})
		}

		const {
			fetchContracts,
			tableColumns,
			perPage,
			currentPage,
			totalContracts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refContractListTable,
			refetchData,

			// EXTRA FILTERS
			contractTypesFilter,
			contractServicesFilter
		} = useContractsList()

		return {
			fetchContracts,
			tableColumns,
			options,
			perPage,
			currentPage,
			totalContracts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refContractListTable,
			refetchData,

			// EXTRA FILTERS
			contractTypesFilter,
			contractServicesFilter,

			// ACTIONS
			avatarText,
			deleteContract,

			formatStringToDate
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>